<template>
	<el-dialog :title="'设置项目相关信息 '" :close-on-click-modal="false" :visible.sync="visible"
		width="600px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-form-item label="项目类型" prop="projectKind">
				<el-select v-model="dataForm.projectKind" placeholder="请选择" clearable>
					<el-option
							v-for="item in projectKindList"
							:key="item.value"
							:label="item.label"
							:value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="周报类型" prop="weeklyReportTypeId">
				<el-select v-model="dataForm.weeklyReportTypeId" placeholder="请选择" clearable>
					<el-option
							v-for="item in weeklyTypeList"
							:key="item.value"
							:label="item.label"
							:value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="月报类型" prop="monthlyReportTypeId">
				<el-select v-model="dataForm.monthlyReportTypeId" placeholder="请选择" clearable>
					<el-option
							v-for="item in monthlyTypeList"
							:key="item.value"
							:label="item.label"
							:value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="承包单位" prop="contractCompany">
				<el-input v-model="dataForm.contractCompany" placeholder="请输入内容" clearable></el-input>
			</el-form-item>
			<el-form-item label="项目简称" prop="projectOtherName">
				<el-input v-model="dataForm.projectOtherName" placeholder="请输入内容" clearable></el-input>
			</el-form-item>
			<el-form-item label="Logo" prop="logoPath">
				<div class="addImgBox" v-if="dataForm.logoPathUrl">
					<el-image
						style="width: 100%; height: 100%"
						:src="dataForm.logoPathUrl"
						fit="cover"></el-image>
					<div class="delBox">
						<i class="el-icon-delete" @click="delImg()"></i>
					</div>
				</div>
				<el-upload v-if="!dataForm.logoPath"
						  class="addImgBox"
						  ref="uploadImg"
						  :action="action"
						  :http-request="uploadImg"
						  accept="image/jpeg,image/png,image/jpg">
						  <el-image
						    style="width: 100%; height: 100%"
						    :src="addImg"
						    fit="cover">
						  </el-image>
						</el-upload>
			</el-form-item>
			<el-form-item label="周报体系编号" prop="weekSystemNum">
				<el-input v-model="dataForm.weekSystemNum" placeholder="请输入内容" clearable></el-input>
			</el-form-item>
			<el-form-item label="月报体系编号" prop="monthSystemNum">
				<el-input v-model="dataForm.monthSystemNum" placeholder="请输入内容" clearable></el-input>
			</el-form-item>
			<el-form-item label="通知单体系编号" prop="noticeSystemNum">
				<el-input v-model="dataForm.noticeSystemNum" placeholder="请输入内容" clearable></el-input>
			</el-form-item>
			<el-form-item label="联系单体系编号" prop="contactSystemNum">
				<el-input v-model="dataForm.contactSystemNum" placeholder="请输入内容" clearable></el-input>
			</el-form-item>
			<el-form-item label="会议体系编号" prop="meetingSystemNum">
				<el-input v-model="dataForm.meetingSystemNum" placeholder="请输入内容" clearable></el-input>
			</el-form-item>
			<el-form-item label="日志体系编号" prop="worklogSystemNum">
				<el-input v-model="dataForm.worklogSystemNum" placeholder="请输入内容" clearable></el-input>
			</el-form-item>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
			<el-button v-preventReClick class="rebtn" size="small" @click="visible = false" >取消</el-button>
		</el-row>
	</el-dialog>

</template>

<script>
	import $common from "@/utils/common.js"
	import addImg from "@/assets/images/addImg.png"
	export default {
		name: "project-kind-setting",
		data() {
			return {
				action:"",
				addImg: addImg,
				//当前页码
				pageIndex: 1,
				//当前记录数
				pageSize: 5,
				// nodeinfoList : [],
				visible: false,
				inline: false,
				activeNames: ['1'],
				plannedTime: '',
				description: '',
				projectKindList: [
					{
						value: '1',
						label: '船舶监理'
					},
					{
						value: '2',
						label: '设备监理'
					}
				],
				weeklyTypeList: [
					{
						value: 'WEEK01',
						label: '上海版本（旧版）'
					},
					{
						value: 'WEEK03',
						label: '上海版本（新版）'
					},
					{
						value: 'WEEK02',
						label: '周报(新版)'
					}
				],
				monthlyTypeList: [
					{
						value: 'MONTH01',
						label: '上海版本（旧版）'
					},
					{
						value: 'MONTH03',
						label: '上海版本（新版）'
					},
				],
				dataForm: {
					workNo: '',
					projectKind: '',
					contractCompany: '',
					logoPath: '',
					logoPathUrl:'',
					weekSystemNum: '',
					monthSystemNum: '',
					noticeSystemNum: '',
					contactSystemNum: '',
					meetingSystemNum: '',
					worklogSystemNum: '',
					weeklyReportTypeId: '',
					monthlyReportTypeId: ''
				},
				dataRule: {
					projectKind: [
						{ required: true, message: "请选择项目类型", trigger: "blur" }
					],
					weeklyReportTypeId: [
						{ required: true, message: "请选择周报类型", trigger: "blur" }
					],
					monthlyReportTypeId: [
						{ required: true, message: "请选择月报类型", trigger: "blur" }
					],
					monitorContent: [
						{ required: true, message: "请填写承包单位", trigger: "blur" }
					],
				},
			}
		},
		mounted() {
		},
		methods: {
			init() {
				// this.dataForm.nodeId = id || 0;
				this.visible = true;
				if (this.$refs["dataForm"]) {
					this.$refs["dataForm"].resetFields()
				}
				this.dataForm = {
					workNo: $common.getItem("workNo"),
					projectKind: '',
					contractCompany: '',
					projectOtherName: '',
					logoPath:'',
					logoPathUrl:'',
					weekSystemNum: '',
					monthSystemNum: '',
					noticeSystemNum: '',
					contactSystemNum: '',
					meetingSystemNum: '',
					worklogSystemNum: '',
					weeklyReportTypeId: '',
					monthlyReportTypeId: ''
				},
				this.$nextTick(() => {
					this.$http({
						url:  this.$store.state.httpUrl + "/index/infoName/"+$common.getItem("workNo"),
						method: "get",
						params: {},
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.dataForm.projectKind = data.body.projectKind
							this.dataForm.contractCompany = data.body.contractCompany
							this.dataForm.projectOtherName = data.body.projectOtherName
							this.dataForm.logoPath = data.body.logoPath
							this.dataForm.logoPathUrl = data.body.logoPathUrl
							this.dataForm.weekSystemNum = data.body.weekSystemNum
							this.dataForm.monthSystemNum = data.body.monthSystemNum
							this.dataForm.noticeSystemNum = data.body.noticeSystemNum
							this.dataForm.contactSystemNum = data.body.contactSystemNum
							this.dataForm.meetingSystemNum = data.body.meetingSystemNum
							this.dataForm.worklogSystemNum = data.body.worklogSystemNum
							this.dataForm.weeklyReportTypeId = data.body.weeklyReportTypeId
							this.dataForm.monthlyReportTypeId = data.body.monthlyReportTypeId
						}
					});
				});
			},
			// 表单提交
			dataFormSubmit() {
				this.$refs["dataForm"].validate(valid => {
					if (valid) {
						this.$http({
							url:  this.$store.state.httpUrl +
									`/business/projectsupervisorinfo/updateProjectSupervisor`,
							method: "post",
							data: this.$http.adornData({
								workNo: $common.getItem("workNo"),
								contractCompany:this.dataForm.contractCompany,
								projectKind:this.dataForm.projectKind,
								projectOtherName:this.dataForm.projectOtherName,
								logoPath:this.dataForm.logoPath,
								weekSystemNum:this.dataForm.weekSystemNum,
								monthSystemNum:this.dataForm.monthSystemNum,
								noticeSystemNum:this.dataForm.noticeSystemNum,
								contactSystemNum:this.dataForm.contactSystemNum,
								meetingSystemNum:this.dataForm.meetingSystemNum,
								worklogSystemNum:this.dataForm.worklogSystemNum,
								weeklyReportTypeId:this.dataForm.weeklyReportTypeId,
								monthlyReportTypeId:this.dataForm.monthlyReportTypeId,
							})
						}).then(({ data }) => {
							if (data && data.resultCode === 200) {
								this.$message({
									message: "操作成功",
									type: "success",
									duration: 1500,
									onClose: () => {
										this.visible = false;
										// this.$parent.getInfoName();
									}
								});
							}
						});
					}
				});
			},
			delImg(){
				this.dataForm.logoPath = ''
				this.dataForm.logoPathUrl = ''
			},
			uploadImg(param){
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadImg.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/"+$common.getItem("workNo")+"/10/0",
				formData,
				{
				  headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
			  res = res.data
			  if (res.resultCode == 200){
				this.dataForm.logoPath = res.body.fileSavePath;
				this.dataForm.logoPathUrl = res.body.fileUri;
			  } else {
				this.$message.info('文件上传失败')
			  }
			})
		},
		}
	}
</script>
<style scoped>
	.selItemInput.descAreaLg {
		width: 380px !important;
	}
	.addImgBox{
		width: 136px;
		height: 136px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
		position: relative;
	}
</style>
